var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-card',[_c('v-container',[_c('v-card-title',{staticClass:"mt-n3"},[_c('span',{staticClass:"primary--text"},[_vm._v(_vm._s(_vm.formEditTitle))])]),_c('v-card-text',[_c('v-form',{ref:"form",attrs:{"form":"form","id":"form"},on:{"submit":function($event){$event.preventDefault();return _vm.saveConfig()}},model:{value:(_vm.isFormValid),callback:function ($$v) {_vm.isFormValid=$$v},expression:"isFormValid"}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.empresasItems,"item-text":"entFacNombre","item-value":"entFacId","clearable":"","disabled":_vm.itemToEdit !== null,"filled":_vm.itemToEdit !== null,"return-object":"","rules":_vm.rules.required,"label":"Empresa","outlined":"","dense":""},on:{"change":function($event){return _vm.setMonedaByEntFac()}},model:{value:(_vm.empresaSelected),callback:function ($$v) {_vm.empresaSelected=$$v},expression:"empresaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('v-autocomplete',{attrs:{"items":_vm.monedasItems,"item-text":"value","item-value":"id","disabled":_vm.disabledMoneda || _vm.itemToEdit !== null,"filled":_vm.disabledMoneda || _vm.itemToEdit !== null,"clearable":"","return-object":"","rules":_vm.rules.required,"label":"Moneda","outlined":"","dense":""},model:{value:(_vm.monedaSelected),callback:function ($$v) {_vm.monedaSelected=$$v},expression:"monedaSelected"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('currency-input',{staticClass:"right-input",attrs:{"label":"Importe desde","options":_vm.currencyOptions,"rules":_vm.rules.required.concat(
                  _vm.rules.validDateRange(
                    parseInt(_vm.importeDesde),
                    parseInt(_vm.importeHasta)
                  )
                )},model:{value:(_vm.importeDesde),callback:function ($$v) {_vm.importeDesde=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"importeDesde"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"cols":"6"}},[_c('currency-input',{staticClass:"right-input",attrs:{"label":"Importe hasta","options":_vm.currencyOptions,"rules":_vm.rules.required.concat(
                  _vm.rules.validDateRange(
                    parseInt(_vm.importeDesde),
                    parseInt(_vm.importeHasta)
                  )
                )},model:{value:(_vm.importeHasta),callback:function ($$v) {_vm.importeHasta=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"importeHasta"}})],1),_c('v-col',{staticClass:"py-0 pt-3",attrs:{"cols":"12"}},[_c('v-autocomplete',{attrs:{"items":_vm.usuariosItems,"item-text":"usuario","item-value":"usuario","label":"Usuarios","outlined":"","multiple":"","dense":"","clearable":""},scopedSlots:_vm._u([{key:"prepend-item",fn:function(){return [_c('v-list-item',{attrs:{"ripple":""},on:{"click":function($event){return _vm.usersToggle()}}},[_c('v-list-item-action',[_c('v-icon',{attrs:{"color":_vm.selectAllUsers ? 'primary' : ''}},[_vm._v(" "+_vm._s(_vm.selectAllUsers ? "mdi-checkbox-marked" : "mdi-checkbox-blank-outline")+" ")])],1),_c('v-list-item-content',[_c('v-list-item-title',[_vm._v(" Todos ")])],1)],1),_c('v-divider',{staticClass:"mt-2"})]},proxy:true}]),model:{value:(_vm.usuariosSelected),callback:function ($$v) {_vm.usuariosSelected=$$v},expression:"usuariosSelected"}})],1)],1)],1)],1),_c('v-card-actions',{staticClass:"mt-n8 mr-2"},[_c('v-spacer'),_c('v-btn',{attrs:{"outlined":""},on:{"click":_vm.closeModal}},[_vm._v(" Cancelar ")]),_c('v-btn',{attrs:{"type":"submit","disabled":!_vm.isFormValid || _vm.usuariosSelected.length === 0,"form":"form","loading":_vm.loadingBtnSave,"color":"primary"}},[_vm._v(" Guardar ")])],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }